<template>
    <el-card shadow="never" class="aui-card--fill">
        <div class="mod-sys__post">
            <el-form
                :inline="true"
                :model="dataForm"
                @keyup.enter.native="getDataList(1)"
            >
                <el-form-item>
                    <el-input
                        v-model="dataForm.id"
                        placeholder="商品ID"
                        clearable
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input
                        v-model="dataForm.name"
                        placeholder="商品名称"
                        clearable
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-select v-model="dataForm.status" placeholder="商品状态">
                        <el-option label="全部" value=""> </el-option>
                        <el-option label="正常" value="1"> </el-option>
                        <el-option label="停用" value="0"> </el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item>
                    <el-select
                        v-model="dataForm.activeHealthCardFlag"
                        placeholder="是否立即激活"
                    >
                        <el-option label="全部" value=""> </el-option>
                        <el-option label="立即激活" :value="1"> </el-option>
                        <el-option label="不立即激活" :value="0"> </el-option>
                    </el-select>
                </el-form-item> -->

                <el-form-item>
                    <el-button @click="getDataList(1)">查询</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button @click="resetHandle()">重置</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="itemEditor({}, 'add')"
                        >新增</el-button
                    >
                </el-form-item>
            </el-form>
            <el-table
                v-loading="dataListLoading"
                :data="dataList"
                border
                style="width: 100%"
            >
                <el-table-column
                    prop="id"
                    label="ID"
                    header-align="center"
                    align="center"
                ></el-table-column>
                <el-table-column
                    prop="name"
                    label="商品名称"
                    header-align="center"
                    align="center"
                ></el-table-column>
                <el-table-column
                    prop="content"
                    label="商品描述"
                    header-align="center"
                    align="center"
                ></el-table-column>
                <el-table-column
                    prop="price"
                    label="公示价格"
                    header-align="center"
                    align="center"
                >
                </el-table-column>
                <el-table-column
                    prop="discountPrice"
                    label="折后价格"
                    fixed="right"
                    header-align="center"
                    align="center"
                    width="150"
                >
                </el-table-column>
                <el-table-column
                    prop="status"
                    label="商品状态"
                    fixed="right"
                    header-align="center"
                    align="center"
                    width="150"
                >
                    <template slot-scope="scope">
                        <span>{{
                            scope.row.status == 1 ? '正常' : '停用'
                        }}</span>
                    </template>
                </el-table-column>

                <el-table-column
                    prop="productType"
                    label="商品类型"
                    fixed="right"
                    header-align="center"
                    align="center"
                    width="150"
                >
                    <template slot-scope="scope">
                        <span>{{
                            scope.row.productType == '01' ? '权益包' : '服务项'
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="createDate"
                    label="创建时间"
                    fixed="right"
                    header-align="center"
                    align="center"
                    width="150"
                >
                </el-table-column>

                <el-table-column
                    label="操作"
                    fixed="right"
                    header-align="center"
                    align="center"
                    width="150"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="itemEditor(scope.row, 'editor')"
                            >编辑</el-button
                        >
                        <el-button
                            type="text"
                            size="small"
                            :disabled="scope.row.status == 1 ? true : false"
                            @click="itemEditor(scope.row, 'del')"
                            >删除</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :current-page="page"
                :page-size="10"
                :total="total"
                layout="total, prev, pager, next"
                @current-change="pageCurrentChangeHandle"
            >
            </el-pagination>

            <Model ref="model" :itemData="itemData" @updatTable="updatTable" />
        </div>
    </el-card>
</template>

<script>
import Model from './components/model';

const urls = {
    page: '/demo/product/page',
    del: '/demo/product',
};

export default {
    data() {
        return {
            //头部表单筛选
            dataForm: {
                id: '',
                name: '',
                status: '',
                // activeHealthCardFlag: '',
            },
            itemData: {},
            dataListLoading: false,
            dataList: [],
            page: 1,
            total: 0,
        };
    },
    components: {
        Model,
    },
    mounted() {
        this.getDataList();
    },

    methods: {
        //表格获取数据
        async getDataList(type) {
            if (type) this.page = 1;
            const { name, id, status } = this.dataForm;
            let params = {
                limit: 10,
                page: this.page,
                name,
                id,
                status,
                // activeHealthCardFlag,
            };
            this.dataListLoading = true;
            const res = await this.$http.get(urls.page, { params });
            this.dataListLoading = false;
            const data = res.data;

            const { code = 99, msg = '网络异常' } = data;

            if (code != 0) {
                this.$message.error(msg);
                return false;
            }
            this.dataList = data.data.list;
            this.total = data.data.total;
        },
        //重置表单
        resetHandle() {
            //Vue中重置data的数据为初始状态
            Object.assign(this.$data, this.$options.data());
            this.getDataList();
        },
        //单个编辑
        async itemEditor(data, type) {
            if (type == 'del') {
                this.$confirm('此操作将永久删除该, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                })
                    .then(async () => {
                        const delRes = await this.$http.delete(urls.del, {
                            data: [data.id],
                        });

                        if (delRes.data.code == 0) {
                            this.getDataList();
                        }
                        this.$message({
                            type: 'success',
                            message: '删除成功!',
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消删除',
                        });
                    });

                return;
            }
            this.itemData = data;
            this.$refs.model.dialogVisible = true;
        },
        //页码操作
        pageCurrentChangeHandle(page) {
            this.page = page;
            this.getDataList();
        },

        updatTable() {
            this.resetHandle();
        },
    },
};
</script>
